<template>
  <div class="page-card-demo  page-info-content">
    <el-form ref="addForm" :inline="true" :model="formParams" :rules="formRules" label-width="180px" size="small">
      <detailTemplate :detail-config="detailConfig" :form-params="formParams" />
    </el-form>
    <div class="add_organazition_btn">
      <el-button size="small" @click="handleCancel">
        取消
      </el-button>
      <el-button type="primary" size="small" @click="handleSubmit">
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
import DetailTemplate from '../../components/detailTemplate.vue'
import { operateArrayObj } from '@/utils/index.js'

export default {
  components: { DetailTemplate },
  data() {
    return {
      formParams: {
        prodName: '', // 产品名称
        guarType: '', // 担保产品类型
        guarTypeName: '', //
        guarCmpId: '', // 担保方
        guarCmpUnicode: '', //
        guarCmpName: '', //
        coreCmpId: '', // 核心企业
        coreCmpUnicode: '', //
        coreCmpName: '', //
        prodAmt: '', // 项目总额度
        compactList: new Set(), // 确认函模板
        prodDescr: '', // 项目描述
        fundDetailList: [], // 合作资金方
        fundCostList: [{
          costName: '', // 费用名称
          costType: '04', // 费用类型
          costGentime: '', // 收取时点
          costNum: '', // 收取额
          costUnit: '02', // 收取方式单位
          minimum: '', // 最低收取
          costPayer: '' // 支付方
        }], // 费用担保方
        platCostList: [{
          costName: '', // 费用名称
          costType: '03', // 费用类型
          costGentime: '', // 收取时点
          costNum: '', // 收取额
          costUnit: '02', // 收取方式单位
          minimum: '', // 最低收取
          costPayer: '' // 支付方
        }] // 费用平台方
      },
      formRules: {
        prodName: [{ required: true, validator: this.prodNameValidator, trigger: 'change' }], // 产品名称
        guarType: [{ required: true, trigger: 'change', message: '请选择担保产品类型' }], // 担保产品类型
        guarCmpId: [{ required: true, trigger: 'change', message: '请选择担保方' }], // 担保方
        coreCmpId: [{ trigger: 'change', message: '请选择核心企业' }], // 核心企业
        prodAmt: [{ required: true, validator: this.prodAmtValidator, trigger: 'change' }], // 项目总额度
        remarks: [{ max: 500, message: '最多500个字符', trigger: 'change' }], // 项目描述
        fundCmpId: [{ required: true, validator: this.fundCmpValidator, trigger: 'change' }], // 授信资金方
        projectAmt: [{ required: true, validator: this.projectAmtValidator, trigger: 'change' }], // 项目额度
        endDate: [{ required: true, trigger: 'change', message: '请选择额度到期日' }], // 额度到期日
        custAmt: [{ required: true, validator: this.custAmtValidator, trigger: 'change' }], // 单户限额
        costName: [{ required: true, trigger: 'change', message: '请输入费用名称' }], // 费用名称
        costType: [{ required: true, trigger: 'change', message: '请选择费用类型' }], // 费用类型
        costGentime: [{ required: true, trigger: 'change', message: '请选择收取时点' }], // 收取时点
        costNum: [{ required: true, validator: this.validateCostNum, trigger: 'change' }], // 收取方式
        minimum: [{ required: true, validator: this.minimumValidator, trigger: 'change' }], // 最低收取
        costPayer: [{ required: true, trigger: 'change', message: '请选择支付方' }] // 支付方
      },
      detailConfig: [],
      fileInputList: [],
      fundDetailList: { // 合作资金方
        creditFundId: '',
        fundCmpId: '', // 授信资金方
        fundCmpName: '',
        fundCmpUnicode: '',
        projectAmt: '', // 项目额度
        endDate: '', // 额度到期日
        abledAmt: '', // 可用额度
        usedAmt: 0, // 已用额度
        execRate: '', // 融资利率
        execFeeRate: '', // 融资手续费率
        custAmt: '' // 单户限额
      }
    }
  },
  computed: {
    coreEnterPriseList() {
      return this.$store.state.tradeFinancing.coreEnterPriseList
    },
    guarCmpList() {
      return this.$store.state.tradeFinancing.guarCmpList
    },
    guarProdType() {
      return this.$store.getters.getDictionaryItem('GUAR_PROD_TYPE')
    },
    // eslint-disable-next-line vue/return-in-computed-property
    coreReverseGuar() {
      const coreReverseGuar = this.$store.getters.getDictionaryItem('CORE_REVERSE_GUAR')
      if (!coreReverseGuar.length) {
        this.getCompactList()
        return coreReverseGuar
      }
    },
    costType() {
      return this.$store.getters.getDictionaryItem('PROD_COST_TYPE')
    },
    collectTime() {
      return this.$store.getters.getDictionaryItem('COLLECT_TIME')
    },
    costUnit() {
      return this.$store.getters.getDictionaryItem('COST_UNIT')
    },
    payer() {
      return this.$store.getters.getDictionaryItem('PAYER')
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      if (!this.$store.state.tradeFinancing.guarCmpList.length) {
        await this.$store.dispatch('tradeFinancing/querGuarCmpList')
      }
      if (!this.$store.state.tradeFinancing.coreEnterPriseList.length) {
        await this.$store.dispatch('tradeFinancing/queryCmpListByCmpRoleList')
      }
      const selectInfo = {
        guarCmp: operateArrayObj(this.guarCmpList, 'guaranteeCmpName', 'id'),
        guarProdType: operateArrayObj(this.guarProdType, 'dictName', 'dictId'),
        coreReverseGuar: operateArrayObj(this.coreReverseGuar, 'dictName', 'dictId'),
        costType: operateArrayObj(this.costType, 'dictName', 'dictId'),
        costUnit: operateArrayObj(this.costUnit, 'dictName', 'dictId'),
        collectTime: operateArrayObj(this.collectTime, 'dictName', 'dictId'),
        payer: operateArrayObj(this.payer, 'dictName', 'dictId'),
        coreEnterPriseList: operateArrayObj(this.coreEnterPriseList, 'cmpName', 'cmpCode')
      }
      this.getDetailConfig(selectInfo)
    },
    getDetailConfig(selectInfo) {
      this.detailConfig = [
        {
          title: '产品基本信息',
          dataType: 'form',
          dataList: [
            {
              label: '产品名称',
              type: 'input',
              labelWidth: '100px',
              value: 'prodName',
              prop: 'prodName'
            },
            {
              label: '担保产品类型',
              type: 'select',
              labelWidth: '120px',
              value: 'guarType',
              prop: 'guarType',
              selectData: selectInfo.guarProdType,
              inputChange: this.guarTypeChange
            },
            {
              label: '担保方',
              type: 'select',
              labelWidth: '100px',
              value: 'guarCmpId',
              prop: 'guarCmpId',
              selectData: selectInfo.guarCmp,
              inputChange: this.guarCmpChange
            },
            {
              label: '核心企业',
              type: 'select',
              labelWidth: '100px',
              value: 'coreCmpId',
              prop: 'coreCmpId',
              rules: this.formRules.coreCmpId,
              selectData: selectInfo.coreEnterPriseList,
              inputChange: this.coreCmpChange
            },
            {
              label: '项目总额度',
              type: 'money',
              labelWidth: '100px',
              value: 'prodAmt',
              prop: 'prodAmt'
            },
            ...this.fileInputList,
            {
              label: '项目描述',
              type: 'textarea',
              labelWidth: '100px',
              value: 'prodDescr',
              prop: 'prodDescr',
              maxLength: 500,
              itemClassName: 'width100'
            }
          ],
          formParams: this.formParams
        },
        {
          title: '合作资金方',
          dataType: 'table',
          needAddBtn: true,
          showSummary: true,
          getSummariesFunc: this.getFundDetailSummaries,
          hasOperate: true,
          operateWidth: 50,
          tableStyle: 'width:100%;',
          operateList: [
            {
              disabledFunc: (scope) => {
                return scope.row.usedAmt > 0
              },
              isCircle: true,
              type: 'danger',
              icon: 'el-icon-delete',
              clickFunc: (row, index) => {
                this.delTableInfo(index, row, 1)
              }
            }
          ],
          addTableList: () => this.addTableInfo(1),
          dataValue: this.formParams.fundDetailList,
          dataList: [
            {
              label: '授信资金方',
              isForm: true,
              required: true,
              type: 'select',
              selectData: [],
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'fundDetailList',
              prop: 'creditFundId',
              rules: this.formRules.fundCmpId,
              value: 'creditFundId',
              inputChange: this.fundCmpChange,
              noClearable: true
            },
            {
              type: 'money',
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              required: true,
              label: '项目额度',
              isForm: true,
              listName: 'fundDetailList',
              prop: 'projectAmt',
              rules: this.formRules.projectAmt,
              value: 'projectAmt',
              inputInput: this.projectAmtInput
            },
            {
              type: 'date',
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              required: true,
              label: '额度到期日',
              isForm: true,
              listName: 'fundDetailList',
              prop: 'endDate',
              rules: this.formRules.endDate,
              value: 'endDate',
              dateOptions: this.endDateOptions,
              valueFormat: 'timestamp'
            },
            {
              type: 'money',
              isForm: false,
              label: '可用额度',
              listName: 'fundDetailList',
              prop: 'abledAmt',
              value: 'abledAmt'
            },
            {
              type: 'money',
              isForm: false,
              label: '已用额度',
              listName: 'fundDetailList',
              prop: 'usedAmt',
              value: 'usedAmt'
            },
            {
              type: 'rateYear',
              isForm: false,
              label: '融资利率',
              listName: 'fundDetailList',
              prop: 'execRate',
              value: 'execRate'
            },
            {
              type: 'rateYear',
              isForm: false,
              label: '融资手续费率',
              minWidth: 100,
              listName: 'fundDetailList',
              prop: 'execFeeRate',
              value: 'execFeeRate'
            },
            {
              type: 'money',
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              required: true,
              label: '单户限额',
              isForm: true,
              listName: 'fundDetailList',
              prop: 'custAmt',
              rules: this.formRules.custAmt,
              value: 'custAmt',
              inputInput: this.custAmtInput
            }
          ]
        },
        {
          title: '费用-担保方',
          dataType: 'table',
          needAddBtn: false,
          hasOperate: false,
          operateWidth: 50,
          tableStyle: 'width:100%;',
          operateList: [
            {
              isCircle: true,
              type: 'danger',
              icon: 'el-icon-delete',
              clickFunc: (row, index) => {
                this.delTableInfo(index, row, 2)
              }
            }
          ],
          addTableList: () => this.addTableInfo(2),
          dataValue: this.formParams.fundCostList,
          dataList: [
            {
              label: '服务名称',
              type: 'input',
              isForm: true,
              required: true,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'fundCostList',
              prop: 'costName',
              rules: this.formRules.costName,
              value: 'costName'
            },
            {
              label: '费用类型',
              isForm: true,
              required: true,
              type: 'select',
              selectData: selectInfo.costType,
              align: 'center',
              noLable: true,
              disabled: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'fundCostList',
              prop: 'costType',
              rules: this.formRules.costType,
              value: 'costType'
            },
            {
              label: '收取时点',
              isForm: true,
              required: true,
              type: 'select',
              selectData: selectInfo.collectTime,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'fundCostList',
              prop: 'costGentime',
              rules: this.formRules.costGentime,
              value: 'costGentime'
            },
            {
              label: '收取方式',
              isForm: true,
              required: true,
              type: 'unit',
              selectData: selectInfo.costUnit,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'fundCostList',
              prop: 'costNum',
              rules: this.formRules.costNum,
              value: 'costNum',
              unit: 'costUnit',
              unitChange: this.unitChange
            },
            {
              label: '最低收取',
              type: 'money',
              isForm: true,
              required: true,
              align: 'center',
              noLable: true,
              disabledFn: (row) => {
                return row.costUnit === '01'
              },
              headerAlign: 'center',
              minWidth: 230,
              listName: 'fundCostList',
              prop: 'minimum',
              rules: this.formRules.minimum,
              value: 'minimum'
            },
            {
              label: '支付方',
              isForm: true,
              required: true,
              type: 'select',
              selectData: selectInfo.payer,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'fundCostList',
              prop: 'costPayer',
              rules: this.formRules.costPayer,
              value: 'costPayer'
            }
          ]
        },
        {
          title: '费用平台方',
          dataType: 'table',
          needAddBtn: false,
          hasOperate: false,
          operateWidth: 50,
          tableStyle: 'width:100%;',
          operateList: [
            {
              isCircle: true,
              type: 'danger',
              icon: 'el-icon-delete',
              clickFunc: (row, index) => {
                this.delTableInfo(index, row, 3)
              }
            }
          ],
          addTableList: () => this.addTableInfo(3),
          dataValue: this.formParams.platCostList,
          dataList: [
            {
              label: '服务名称',
              type: 'input',
              isForm: true,
              required: true,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'platCostList',
              prop: 'costName',
              rules: this.formRules.costName,
              value: 'costName'
            },
            {
              label: '费用类型',
              isForm: true,
              required: true,
              type: 'select',
              selectData: selectInfo.costType,
              align: 'center',
              disabled: true,
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'platCostList',
              prop: 'costType',
              rules: this.formRules.costType,
              value: 'costType'
            },
            {
              label: '收取时点',
              isForm: true,
              required: true,
              type: 'select',
              selectData: selectInfo.collectTime,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'platCostList',
              prop: 'costGentime',
              rules: this.formRules.costGentime,
              value: 'costGentime'
            },
            {
              label: '收取方式',
              isForm: true,
              required: true,
              type: 'unit',
              selectData: selectInfo.costUnit,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'platCostList',
              prop: 'costNum',
              rules: this.formRules.costNum,
              value: 'costNum',
              unit: 'costUnit',
              unitChange: this.unitChange
            },
            {
              label: '最低收取',
              type: 'money',
              isForm: true,
              required: true,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              disabledFn: (row) => {
                return row.costUnit === '01'
              },
              listName: 'platCostList',
              prop: 'minimum',
              rules: this.formRules.minimum,
              value: 'minimum'
            },
            {
              label: '支付方',
              isForm: true,
              required: true,
              type: 'select',
              selectData: selectInfo.payer,
              align: 'center',
              noLable: true,
              headerAlign: 'center',
              minWidth: 230,
              listName: 'platCostList',
              prop: 'costPayer',
              rules: this.formRules.costPayer,
              value: 'costPayer'
            }
          ]
        }
      ]
    },
    // 新增合作资金方  新增费用担保方  新增费用平台方
    addTableInfo(t) {
      switch (t) {
        case 1:
        case '1':
          this.addInfo(this.formParams.fundDetailList, this.fundDetailList, 0)
          break
        case 2:
        case '2':
          this.addInfo(this.formParams.fundCostList, this.fundCostList, 1)
          break
        case 3:
        case '3':
          this.addInfo(this.formParams.platCostList, this.platCostList, 1)
          break
      }
    },
    // 新增
    addInfo(list, obj, t) {
      if (t) {
        if (!list.length) {
          list.push({
            ...JSON.parse(JSON.stringify(obj))
          })
        } else {
          this.$message({
            type: 'info',
            message: '费用类型不能重复，如需增加请联系运营人员！'
          })
        }
      } else {
        list.push({
          ...JSON.parse(JSON.stringify(obj))
        })
      }
    },
    // 获取文件名和下拉list
    getCompactList() {
      this.fileInputList = []
      this.compactList = new Set()
      this.coreReverseGuar.forEach(item => {
        const list = this.$store.getters.getDictionaryItem(item.dictId)
        const t = { list, type: item.dictId, name: item.dictName }
        this.compactList.add(t)
        this.compactList.forEach(v => {
          this.formParams[`compactId${v.type}`] = null
          this.formParams[`filesAsd${v.type}`] = null
          const t = {
            label: v.name,
            type: 'select',
            labelWidth: '100px',
            value: `compactId${v.type}`,
            prop: `compactId${v.type}`,
            selectData: operateArrayObj(v.list, 'compactName', 'compactId'),
            inputChange: (e, row, i) => this.compactChange(e, row, i, v)
          }
          this.fileInputList.push(t)
        })
        this.detailConfig[0]['dataList'].splice(5, 0, ...this.fileInputList)
      })
    },
    handleCancel() {},
    handleSubmit() {}
  }
}
</script>
<style lang="scss" scoped>
  .add_organazition_btn {
    text-align: center;
    margin: 20px 0;
  }
</style>
